import React, { useEffect, useState } from "react";
import Seat from "../Seat";
import axios from "axios";
import { API_URL } from "../../config/indext";

const getDataRoomFloor = async (props) => {
  const { setIsloading, setDataFloorTranNao } = props;
  setIsloading(true);
  await axios
    .get(`${API_URL}/seat/trannao`)
    .then((res) => {
      setIsloading(false);
      const response = res.data;
      setDataFloorTranNao(response);
    })
    .catch((error) => {
      setIsloading(false);
      console.log(error);
    });
};

export default function TranNao() {
  const [isloading, setIsloading] = useState(false);
  const [dataFloorTranNao, setDataFloorTranNao] = useState([]);

  useEffect(() => {
    if (!isloading) {
      getDataRoomFloor({ setIsloading, setDataFloorTranNao });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="container-floor" style={{ padding: "7% 10% 12% 7%" }}>
      <div className="d-flex">
        {dataFloorTranNao?.data_room_trannao_1?.length > 0 ? (
          dataFloorTranNao?.data_room_trannao_1?.map((item) => (
            <Seat lyingHorizontally dataDetailUser={item} />
          ))
        ) : (
          <>
            {[...Array(3)].map(() => (
              <Seat lyingHorizontally />
            ))}
          </>
        )}
        {/* <Seat lyingHorizontally />
        <Seat lyingHorizontally />
        <Seat lyingHorizontally /> */}
      </div>
      <div>
        <div className="d-flex">
          {dataFloorTranNao?.data_room_trannao_2?.length > 0 ? (
            dataFloorTranNao?.data_room_trannao_2?.map((item) => (
              <Seat lyingHorizontally dataDetailUser={item} />
            ))
          ) : (
            <>
              {[...Array(5)].map(() => (
                <Seat lyingHorizontally />
              ))}
            </>
          )}
          {/* <Seat lyingHorizontally />
          <Seat lyingHorizontally />
          <Seat lyingHorizontally />
          <Seat lyingHorizontally />
          <Seat lyingHorizontally /> */}
        </div>
        <div className="d-flex">
          {dataFloorTranNao?.data_room_trannao_3?.length > 0 ? (
            dataFloorTranNao?.data_room_trannao_3?.map((item) => (
              <Seat horizontal dataDetailUser={item} />
            ))
          ) : (
            <>
              {[...Array(5)].map(() => (
                <Seat horizontal />
              ))}
            </>
          )}
          {/* <Seat horizontal />
          <Seat horizontal />
          <Seat horizontal />
          <Seat horizontal />
          <Seat horizontal /> */}
        </div>
      </div>
    </div>
  );
}
